import dragdealer from 'dragdealer';
import hasTouch from 'has-touch';

function isInViewport(element) {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}

const $ = document.querySelector.bind(document)
const $$ = document.querySelectorAll.bind(document)

// Contact overlay
document.addEventListener("keydown", (e) => {
  if (e.keyCode === 27) {
    window.location.hash = "#";
    closeContact();
  }
});

// Turn off video controls on desktop
const videos = $$('video');

if (videos.length) {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    Array.prototype.map.call(videos, (video) => video.setAttribute('controls', true))
  }
}

// Sketch Cloud

if (document.body.classList.contains('sketch-cloud')) {
  const animation = $('.sketch-cloud-case-animation')

  const onScrollPage = () => {
    if (isInViewport(animation)) {
      animation.classList.add('is-playing')
      document.removeEventListener('scroll', onScrollPage)
    }
  }

  document.addEventListener('scroll', onScrollPage)
}

// About

if (document.body.classList.contains("about")) {

  // How hover actions
  let howItems = $$('.How-item');
  let howContainer = $(".How");

  const mouseOverHowItem = (e) => {
    const topic = e.target.dataset.how;
    howContainer.dataset.currentTopic = `topic-${topic}`
  }

  Array.prototype.map.call(howItems, howItem => howItem.addEventListener('mouseover', mouseOverHowItem))

}

// Finer Fields

if (document.body.classList.contains("finerfields")) {

  // Set interval
  let snapScrollInterval = window.setInterval(moveStream, 1500);

  let currentStreamStep = 0;

  function moveStream() {

    if (currentStreamStep < 4) {
      $('.Case--ft__snapscroll-block').classList.remove('snapscroll-'+(currentStreamStep-1))
      $('.Case--ft__snapscroll-block').classList.add('snapscroll-'+currentStreamStep)
    } else {

      currentStreamStep = 0;

      $('.Case--ft__snapscroll-block').classList.remove('snapscroll-3')
      $('.Case--ft__snapscroll-block').classList.add('snapscroll-0')
    }

    currentStreamStep++;
  }

}

// Beam

// Darkmode beam case
if ($$('#darkmode').length && window.matchMedia("(min-width: 480px)").matches) {
  const Dragdealer = dragdealer.Dragdealer
  const light = $('.DarkLight .Light')
  const dark = $('.DarkLight .Dark')
  const darkmodeElement = $('#darkmode')

  const onResize = function () {
    dark.style.width = `${light.getBoundingClientRect().width}px`
  }

  window.addEventListener('resize', onResize)
  onResize()

  const slider = new Dragdealer('DarkLight__slider', {
    speed: 0.1,
    loose: false,
    animationCallback: (offset) => {
      $('.DarkLight .mask').style.width = `calc(${offset * 100}%)`

      $('.DarkLight .handle').style.left = `${-25 + offset * 25 * 2}px`
    }
  })

  const setPosition = function () {
    if (isInViewport(darkmodeElement)) {
      slider.setValue(0.03)
      window.removeEventListener('scroll', setPosition)
    }
  }

  window.addEventListener('scroll', setPosition)
}

// Revue

if (document.body.classList.contains("revue")) {

  const moveIntoPlace = $('.animated-object')

  const setPosition = function () {
    if (isInViewport(moveIntoPlace)) {
      moveIntoPlace.classList.add('transition');
      window.removeEventListener('scroll', setPosition)
    }
  }

  window.addEventListener('scroll', setPosition)

}


// Mollie website

if (document.body.classList.contains("mollie")) {

  const Dragdealer = dragdealer.Dragdealer;

  let i = 0;

  const description = [
    'We started with an isomorphic style, using neutral colors and vibrant red for the money trucks.',
    "The money truck symbolized Mollie's part in the transaction process.",
    'We tried to position Mollie as a friendly face in a bland, stern, financial world.',
    'We felt this direction focussed too much on Mollie, instead of the users. Also, the style was not what we were looking for.',
    'Using a minimal style we made an overview of all the different types of businesses using Mollie.',
    'We felt we were on to something and began refining. Still, there was a big part missing: developers.',
    "The final version has a minimal, yet friendly, style and shows merchants, Mollie and developers in a single illustration."
  ]

  const slider = new Dragdealer('mollie-slider', {

    steps: 7,
    speed: 0.1,
    loose: true,
    requestAnimationFrame: true,
    callback: () => {

      const moveHandle = $('.moveHandle');

      if(moveHandle) moveHandle.classList.remove('moveHandle');
    },
    animationCallback: function (x, y) {
      i++;

      const moveHandle = $('.moveHandle');

      if (i > 1 && moveHandle) moveHandle.classList.remove('moveHandle');

      let currentStep = this.getStep()[0];

      $('.counter span').textContent = currentStep;
      $('.description').textContent = description[currentStep-1];
    }

  });

}

// Ventures
if (document.body.classList.contains("ventures")) {
  const items = Array.prototype.slice.call($$('.Timeline__item'))

  items[0].classList.add('Timeline__item--active')

  const Dragdealer = dragdealer.Dragdealer

  const slider = new Dragdealer('timeline', {
    handleClass: 'Timeline__handle',
    steps: 9,
    speed: 0.3,
    loose: true,
    requestAnimationFrame: true,
    callback: function () {
      const currentStep = this.getStep()[0]

      items.forEach((item) => item.classList.remove('Timeline__item--active'))

      items[currentStep - 1].classList.add('Timeline__item--active')
    }
  })

  window.addEventListener('keyup', function (e) {
    const { keyCode } = e

    switch (keyCode) {
      case 37:
        slider.setStep(slider.getStep()[0] - 1)
      break
      case 39:
        slider.setStep(slider.getStep()[0] + 1)
      break
    }
  })
}

// Articles
if ($$('#articles').length) {

  const articles = Array.prototype.slice.call($$('#articles .jobs-list--item'))
  let index = 0;

  function showArticles (index, articles) {
    let showFirstNArticles = index * 4;
    let showArticles = articles.slice(0, showFirstNArticles)

    showArticles.forEach((article) => {
      article.style.display = 'block'
    })

    if (index * 4 >= articles.length) {
      $('#js-articles-read-more').style.display = 'none'
    }

  }

  // show first 4 articles
  showArticles(++index, articles)

  // show more on button press
  $('#js-articles-read-more').addEventListener('click', (e) => {
    showArticles(++index, articles)
  })
}

// Error
if ($$('.container-notfound h1').length) {
  window.setTimeout(() => {
    $('.container-notfound h1').textContent = "Deal with it";
  }, 2000);
}

// Color sync
const referenceElement = $('.js-colorSync, .js-bgSync, .js-hoverBgSync, .js-borderSync');

// Events
let iterationCount = 0;

const onAnimationIteration = () => {
  window.sessionStorage.setItem("colorsync-iteration-count", iterationCount++);
}

// Add listeners

const animationIterationEvents = ['animationiteration', 'webkitAnimationIteration', 'MSanimationIteration', 'oAnimationIteration'];

animationIterationEvents.forEach((event) => {
  referenceElement.addEventListener(event, onAnimationIteration);
});

// Show prototype on desktop, hide on mobile
if($('.save-resources-framer-prototype')){
  const container = $('.save-resources-framer-prototype');
  if(!hasTouch){
    $('.save-resources-framer-prototype').innerHTML= '<iframe src="https://share.framerjs.com/kqhek26b4gsk/" style="width: 100%; max-width: 1440px; height: 1000px; border: 0;"></iframe>';
  }else{
    $('.save-resources-framer-prototype').innerHTML = '<img src="/assets/images/blog/prototyping-framer-preview.jpg" /><p><a href="http://share.framerjs.com/xpmw5vey14ut/">Checkout the prototype here</a></p>';
  }

}
